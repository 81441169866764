@use 'src/src/app/scss/abstracts' as *;

.cart-modal {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__body {
    @include scrollbar;

    border-top-left-radius: $border-radius-s;
    border-top-right-radius: $border-radius-s;
    overflow-y: scroll;
    overscroll-behavior: contain;

    // height: 555px;
    height: 48.5dvh;
    min-height: 452px;
  }

  &__row {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(90 90 115 / 40%);

    @include mq($until: md) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__info {
    padding-top: 25px;
    border-top: 1px solid rgb(90 90 115 / 40%);
    margin-bottom: 40px;

    @include mq($until: md) {
      padding-top: 20px;
      margin-bottom: 25px;
    }
  }

  &__info-text {
    color: $color-base-gray-500;
    font-family: $font-family-headings;
    font-size: 16px;
    font-weight: $font-weight-m;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
  }

  &__info-top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mq($until: md) {
      margin-bottom: 15px;
    }
  }

  &__price {
    flex: 0 0 auto;
    color: $color-base-purple-200;
    font-family: $font-family-headings;
    font-size: 28px;
    font-weight: $font-weight-l;
    line-height: 31px;
    letter-spacing: -0.56px;
  }

  &__subtotal {
    flex: 0 0 auto;
    margin-top: 0;
    margin-bottom: 0;
    color: $color-base-purple-200;
    font-family: $font-family-headings;
    font-size: 28px;
    font-weight: $font-weight-l;
    line-height: 31px;
    letter-spacing: -0.56px;
    margin-right: 20px;
  }
}
